
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import useTenantSettingsStore from '@/stores/tenantSettings';

@Component({
  name: 'Entry',
})
export default class Entry extends Vue {
  private tenantSettingsStore = useTenantSettingsStore();
}
